<template>
  <el-table :data="tableData" stripe > <el-table-column prop="date" label="名稱" width="200">
    </el-table-column>
    <el-table-column prop="name" label="修改" width="200" style="font-size: 40px;">
      <template slot-scope="scope">
        <el-button type="text" size="big" @click="jump(scope.$index)" >修改匯率</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data() {
    return {
      tableData: [{
        date: '太子1',
      }, {
        date: '上環4',
      },{
        date: '嘉芬大廈地下J鋪',
      }]
    }
  },
  methods: {
    jump(index) {
      index = index+1
        this.$router.push({
        name: 'Test',
        params: {
          index: index
        }
      })
    },
  }
}
</script>
<style>
.el-table>>>.el-table{
  font-size: 30px;
}
</style>