import Vue from 'vue'
import VueRouter from 'vue-router'
import Test from '../views/Test.vue'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [{
    name: 'Home',
    path: '/',
    component:Home,
  },
  {
    name: 'Test',
    path: '/test',
    component: Test,
  }
  ]
})
